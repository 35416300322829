import type { ReactNode } from "react";
import { useState } from "react";
import { WTextField } from "../../components/forms/WTextField.tsx";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import { AInput } from "../form/Fields.tsx";
import { adminUserCreateMutation } from "./adminUsersQuery.ts";

export function AddAdminUserButton({ button }: { button: ReactNode }) {
    return (
        <WModal button={button}>
            <AddAdminUserModalContent />
        </WModal>
    );
}

export function AddAdminUserModalContent() {
    const createMutation = useStandardMutation(adminUserCreateMutation);

    const [username, setUsername] = useState<string>("");

    async function onSubmit() {
        await createMutation.mutateAsync({
            body: {
                username: username,
            },
        });
    }

    return (
        <WModalContent title="Add New Admin User" label="Add" modalAction={async () => await onSubmit()}>
            <WTextField block wide autoFocus className="min-w-24rem -my-2" label="Username" onChange={setUsername}>
                <AInput value={username} />
            </WTextField>
        </WModalContent>
    );
}
