import S from "../../components/forms/Switch.module.css";

import { type ReactNode, Suspense } from "react";
import { Switch } from "react-aria-components";
import { EditableButton } from "../../components/forms/EditableButton.tsx";
import { CurrencyPromo } from "../../components/l10n/Currency.tsx";
import { Loading } from "../../components/loading/Loading.tsx";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { Separator } from "../../components/Separator.tsx";
import { WTableBody } from "../../components/table/WTable.tsx";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { AdminTitle } from "../AdminTitle.tsx";
import { WAdminTable } from "../WAdminTable.tsx";
import { ReferralAmountsButton } from "./ReferralAmountsModal.tsx";
import { referralAmountsQueryAtom } from "./referralAmountsQuery.ts";
import { referralEnabledQueryAtom, toggleEnabledMutation } from "./referralEnabledQuery.ts";

function ReferralViewCustom() {
    const enabled = useSuspenseQueryAtom(referralEnabledQueryAtom);
    const amounts = useSuspenseQueryAtom(referralAmountsQueryAtom);
    const isEnabled = enabled?.enabled;

    return (
        <>
            <AdminTitle title="Referral Program" />

            <div className="p-2">
                <p className="pb-3 color-muted">
                    Referral program motivates existing users (promoters) to invite new users (receivers) to the
                    platform.
                </p>

                <EnableDisableModal
                    isEnabled={isEnabled}
                    button={
                        <Switch className={S.Switch} isSelected={isEnabled} onChange={undefined}>
                            <div className={S.indicator} />
                            <span className="color-muted">Enable / Disable</span>
                        </Switch>
                    }
                />
            </div>

            <Separator />

            <WAdminTable>
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Description</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <WTableBody>
                    <tr>
                        <td>Promoter Credit</td>
                        <td>Reward given to existing user for each new user brought in</td>
                        <td>
                            <ReferralAmountsButton
                                button={
                                    <EditableButton action={undefined}>
                                        <CurrencyPromo value={amounts?.promoter_credit} />
                                    </EditableButton>
                                }
                                amounts={amounts}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Receiver Credit</td>
                        <td>
                            Additional reward given to new users for using a referral code. (Total credit given = First
                            free credit amount + Receiver Credit)
                        </td>
                        <td>
                            <ReferralAmountsButton
                                button={
                                    <EditableButton action={undefined}>
                                        <CurrencyPromo value={amounts?.receiver_credit} />
                                    </EditableButton>
                                }
                                amounts={amounts}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Receiver top up requirement</td>
                        <td>
                            Total amount of top ups required to be made by the referral code receiver before they and
                            the promoter get the reward credit.
                        </td>
                        <td>
                            <ReferralAmountsButton
                                button={
                                    <EditableButton action={undefined}>
                                        <CurrencyPromo
                                            value={amounts?.referral_credit_receiver_precondition_topup_sum}
                                        />
                                    </EditableButton>
                                }
                                amounts={amounts}
                            />
                        </td>
                    </tr>
                </WTableBody>
            </WAdminTable>
        </>
    );
}

function EnableDisableModal({ button, isEnabled }: { button: ReactNode; isEnabled: boolean }) {
    const toggleMutation = useStandardMutation(toggleEnabledMutation);

    async function onToggle() {
        await toggleMutation.mutateAsync({ enabled: !isEnabled });
    }

    return (
        <WModal button={button}>
            <WModalContent
                title={isEnabled ? "Disable Referral Program" : "Enable Referral Program"}
                label={isEnabled ? "Disable" : "Enable"}
                modalAction={async () => await onToggle()}
            >
                {isEnabled ? (
                    <p>
                        If Referral Program is enabled then users can see their share codes and links on Dashboard and
                        Billing Account view. Disabling the program will hide the codes for existing users and will not
                        allow previously shared codes to be used when signing up.
                    </p>
                ) : (
                    <p>
                        If Referral Program is enabled then users can see their share codes and links on Dashboard and
                        Billing Account view. Disabling the program will hide the codes for existing users and will not
                        allow previously shared codes to be used when signing up.
                    </p>
                )}
            </WModalContent>
        </WModal>
    );
}

export function ReferralView() {
    return (
        <Suspense fallback={<Loading key="loading" delay={0} icon="none" white />}>
            <ReferralViewCustom />
        </Suspense>
    );
}
