import { useState } from "react";
import { WTextField } from "../../components/forms/WTextField.tsx";
import { WModalContent } from "../../components/modal/WModal.tsx";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import { AInput } from "../form/Fields.tsx";
import { googleTagManagerMutation } from "./googleTagManagerQuery.ts";

export function UpdateGoogleTagManagerKeyModal({ value }: { value: string }) {
    //#region Hooks

    const updateMutation = useStandardMutation(googleTagManagerMutation);
    const [key, setKey] = useState(value);

    //#endregion

    return (
        <WModalContent
            title="Update Google Tag Manager key"
            label="Update"
            modalAction={async () => {
                await updateMutation.mutateAsync({
                    gtm_key: key,
                });
            }}
        >
            <WTextField wide autoFocus label="GTM key" value={key} onChange={setKey}>
                <AInput />
            </WTextField>
        </WModalContent>
    );
}
