import { Suspense } from "react";
import { EditableButton } from "../../components/forms/EditableButton.tsx";
import { Loading } from "../../components/loading/Loading.tsx";
import { WModal } from "../../components/modal/WModal.tsx";
import { WTableBody } from "../../components/table/WTable.tsx";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { AdminTitle } from "../AdminTitle.tsx";
import { WAdminTable } from "../WAdminTable.tsx";
import { googleTagManagerQueryAtom } from "./googleTagManagerQuery.ts";
import { UpdateGoogleTagManagerKeyModal } from "./UpdateGoogleTagManagerKeyModal.tsx";

function TrackingViewCustom() {
    const gtm_key = useSuspenseQueryAtom(googleTagManagerQueryAtom);

    return (
        <>
            <AdminTitle title="Tracking Codes" />

            <WAdminTable>
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Description</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <WTableBody>
                    <tr>
                        <td>Google Tag Manager key</td>
                        <td>
                            Add or edit your Google Tag Manager key to enable platform tracking. (Key format:
                            GTM-xxxxxxx)
                        </td>
                        <td>
                            <WModal button={<EditableButton action={undefined}>{gtm_key?.gtm_key}</EditableButton>}>
                                <UpdateGoogleTagManagerKeyModal value={gtm_key?.gtm_key} />
                            </WModal>
                        </td>
                    </tr>
                </WTableBody>
            </WAdminTable>
        </>
    );
}

export function TrackingView() {
    return (
        <Suspense fallback={<Loading key="loading" delay={0} icon="none" white />}>
            <TrackingViewCustom />
        </Suspense>
    );
}
