import type { ReferralAmounts } from "@warrenio/api-spec/spec.oats.gen";
import { type ReactNode, useState } from "react";
import { WTextField } from "../../components/forms/WTextField.tsx";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import { AInput } from "../form/Fields.tsx";
import { updateAmountsMutation } from "./referralAmountsQuery.ts";

interface ReferralAmountsModalProps {
    amounts: ReferralAmounts;
}

interface PaymentMethodButtonProps extends ReferralAmountsModalProps {
    button: ReactNode;
}

export function ReferralAmountsButton(props: PaymentMethodButtonProps) {
    return (
        <WModal button={props.button}>
            <ReferralAmountsModalContent {...props} />
        </WModal>
    );
}

function ReferralAmountsModalContent(props: ReferralAmountsModalProps) {
    const { amounts } = props;
    const modifyMutation = useStandardMutation(updateAmountsMutation);
    const [promoter, setPromoter] = useState<number | string>(amounts.promoter_credit);
    const [receiver, setReceiver] = useState<number | string>(amounts.receiver_credit);
    const [topup, setTopup] = useState<number | string>(amounts.referral_credit_receiver_precondition_topup_sum);

    async function onSubmit() {
        await modifyMutation.mutateAsync({
            body: {
                promoter_credit: promoter ? Number(promoter) : 0,
                receiver_credit: receiver ? Number(receiver) : 0,
                referral_credit_receiver_precondition_topup_sum: topup ? Number(topup) : 0,
            },
        });
    }

    return (
        <WModalContent
            title="Update Referral Program Amounts"
            label="Update"
            modalAction={async () => await onSubmit()}
        >
            <WTextField autoFocus className="max-w-24rem" wide label="Promoter credit amount" onChange={setPromoter}>
                <AInput value={promoter} />
            </WTextField>

            <WTextField className="max-w-24rem" wide label="Receiver credit amount" onChange={setReceiver}>
                <AInput value={receiver} />
            </WTextField>

            <WTextField className="max-w-24rem" wide label="Receiver top up requirement" onChange={setTopup}>
                <AInput value={topup} />
            </WTextField>
        </WModalContent>
    );
}
