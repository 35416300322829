import { type ReactNode, useState } from "react";
import { WTextField } from "../../components/forms/WTextField.tsx";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import { AInput } from "../form/Fields.tsx";
import {
    updateFirstAccountCreditMutation,
    updateMinTopupAmountMutation,
    updateRestrictionLevelClearTopupThresholdMutation,
} from "./settingsQuery.ts";

interface ConfigPaymentModalProps {
    value: number;
    type: string;
    title: string;
}

interface PaymentMethodButtonProps extends ConfigPaymentModalProps {
    button: ReactNode;
}

export function ConfigPaymentButton(props: PaymentMethodButtonProps) {
    return (
        <WModal button={props.button}>
            <ConfigPaymentModalContent {...props} />
        </WModal>
    );
}

function ConfigPaymentModalContent(props: ConfigPaymentModalProps) {
    const { value, type, title } = props;
    const firstMutation = useStandardMutation(updateFirstAccountCreditMutation);
    const topupMutation = useStandardMutation(updateMinTopupAmountMutation);
    const levelMutation = useStandardMutation(updateRestrictionLevelClearTopupThresholdMutation);
    const [current, setCurrent] = useState<number | string>(value);

    async function onSubmit() {
        if (type === "first_account_credit") {
            await firstMutation.mutateAsync({
                body: { first_account_credit: Number(current) },
            });
        } else if (type === "min_topup_amount") {
            await topupMutation.mutateAsync({
                body: { min_topup_amount: Number(current) },
            });
        } else if (type === "restriction_level_clear_topup_threshold") {
            await levelMutation.mutateAsync({
                body: { restriction_level_clear_topup_threshold: Number(current) },
            });
        }
    }

    return (
        <WModalContent
            title={`Update ${title.toLowerCase()}`}
            label="Update"
            modalAction={async () => await onSubmit()}
        >
            <WTextField isRequired autoFocus className="max-w-24rem" wide label={title} onChange={setCurrent}>
                <AInput value={current} />
            </WTextField>
        </WModalContent>
    );
}
